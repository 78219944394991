<main>


    <section class="hm_banner_sec">
        <app-header></app-header>
        <owl-carousel-o [options]="headerBaner">
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Residence </h1>
                            <h2>Peechangode </h2>

                        </div>

                    </div>

                    <img src="assets/banners/banner1.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Yatch House </h1>
                            <h2>Kuttiyadi  </h2>

                        </div>

                    </div>

                    <img src="assets/banners/banner2.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Ponniyam Mosque </h1>
                            <h2>Thalassery </h2>

                        </div>

                    </div>

                    <img src="assets/banners/MOSQUE.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Yatch House </h1>
                            <h2>Kuttiyadi </h2>

                        </div>

                    </div>

                    <img src="assets/banners/pr2.jpg" style="height: 100vh;">

                </div>
            </ng-template> -->
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Coffee Green Resort </h1>
                            <h2>Wayanad </h2>

                        </div>

                    </div>

                    <img src="assets/banners/pr3.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Navas House </h1>
                            <h2>Kuttiyadi </h2>

                        </div>

                    </div>

                    <img src="assets/banners/Navas-kuttyadi.jpg" style="height: 100vh;">

                </div>
            </ng-template> -->
            <!-- <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1><Kbd>Kalpetta Residence</Kbd> </h1>
                            <h2>Kalpetta </h2>

                        </div>

                    </div>

                    <img src="assets/banners/Kalpetta-residence.jpg">

                </div>
            </ng-template> -->
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Super Market </h1>
                            <h2>Banglore </h2>

                        </div>

                    </div>

                    <img src="assets/banners/banglore-super-market.jpg" style="height: 100vh;">

                </div>
            </ng-template>
          <ng-template carouselSlide>
            <div class="owl-item banner_owl">
                <div class="container">
                    <div class="banner_content">

                        <h1>Model High School </h1>
                        <h2>Vellamunda</h2>

                    </div>

                </div>

                <img src="assets/banners/Model-High-School-Vellamunda.jpg" style="height: 100vh;">

            </div>
          </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Naser (Rajadhani) Residence</h1>
                            <h2>Kalpetta </h2>

                        </div>

                    </div>

                    <img src="assets/banners/Naser-Rajadhani.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Residence</h1>
                            <h2>Vellamunda</h2>

                        </div>

                    </div>

                    <img src="assets/banners/pr4.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Sanitary World Commercial </h1>
                            <h2>Mananthavady</h2>

                        </div>

                    </div>

                    <img src="assets/banners/Sanitary-World.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Six Brothers Villas  </h1>
                            <h2>Naikatty, Wayanad </h2>

                        </div>

                    </div>

                    <img src="assets/banners/Six-Brothers-Villas.jpg" style="height: 100vh;">

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Snehatheeram Convention Centre </h1>
                            <h2>Omassery </h2>

                        </div>

                    </div>

                    <img src="assets/banners/Snehatheeram-Convention-Centre.jpg" >

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Seemax Convention Center </h1>
                            <h2>Kutyyadi </h2>

                        </div>

                    </div>

                    <img src="assets/banners/SC04.jpg" >

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="owl-item banner_owl">
                    <div class="container">
                        <div class="banner_content">

                            <h1>Residence </h1>
                            <h2>Peechangod </h2>

                        </div>

                    </div>

                    <img src="assets/banners/residences.jpg" style="height: 100vh;">

                </div>
            </ng-template>
        </owl-carousel-o>

        <div class="explore_btn">
            <!-- <h2> Scroll Down</h2> -->
            <div class="arrow-container">
                <div class="arrow-down"></div>
            </div>
        </div>

    </section>








    <!-- <div class="banner_image" style="width: 100%;height: 500px;overflow: hidden;">

    <img src="assets/banner_image.png" style="position: absolute;z-index: 1;top: 0;height: 500px;width: 100%;">
</div> -->

    <section class="section_1">
        <div class="container">
            <div class="row">
                <div class="s1_content">
                    <h1> For over twenty five years,</h1>
                    <p>Prakriti Architects has been enhancing the way people experience architecture and interior spaces. We thrive on breaking barriers and developing simple solutions to complex challenges.</p>
                    <div class="s1_count">
                        <div class="count1">
                            <h1> {{experience}}+</h1>
                            <p>Years Experience</p>
                        </div>
                        <div class="count2">
                            <h1> {{projectsCount}}+</h1>
                            <p>Projects </p>
                        </div>
                        <div class="count3">
                            <h1> {{sqr}}L+</h1>
                            <p>Total Sq.Ft. Designed </p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>


    <section class="hm_project_sec" *ngIf="projects  && projects.length > 0">
        <owl-carousel-o [options]="customOptions">

            <ng-template carouselSlide *ngFor="let proj of projects">
                <div class="owl-item prj_owl">
                    <div class="container">
                        <div class="prj_content">
                            <h1>Projects</h1>
                            <h2>{{proj.title}}</h2>
                            <p>{{proj.sub_title}}</p>
                            <a [routerLink]="['/project-details',proj.id]">Know More</a>
                        </div>

                    </div>

                    <img [src]="(( proj && proj.images && proj.images.length > 0)? proj.images[0].image_url : proj.image_url) ">

                </div>
            </ng-template>



        </owl-carousel-o>

    </section>


    <section class="hm_services">
        <div class="container hms_inner">
            <div class="row">

                <div class="hm_heading_1">OUR SERVICES</div>
                <h2>Building Spaces from <br> the Inside Out.</h2>

                <div class="hm_services_main row">
                    <div class="hm_service_block col-md-4">
                        <img src="assets/Services-icons/Architecture.png">
                        <h2>Architecture</h2>
                        <p>We try to feed your imagination by delivering the most exciting and outstanding selection of designs and architecture ideas which include all kinds of designs. We focus on sustainable architecture, green building, eco friendly projects and modern designs.</p>
                        <!-- <a> Read More</a> -->
                    </div>

                    <div class="hm_service_block col-md-4">
                        <img src="assets/Services-icons/Interior.png">
                        <h2>Interior</h2>
                        <p>We provide expert interior designs which makes maximum space utilization with minimum designing and we aims at creating the ambience of safety, comfort, happiness etc. Our obsession is distinctive interior and we balance comfort with luxury, the practical with the desirable.</p>
                        <!-- <a> Read More</a> -->
                    </div>

                    <div class="hm_service_block col-md-4 ">
                        <img src="assets/Services-icons/Landscape.png">
                        <h2>Landscape</h2>
                        <p>We are specialized in simple, attractive and warm full designs which are pleasing to eyes, and we try to keep maximum of the actual terrain. We provide easy and beautiful landscaping ideas for your yard.</p>
                        <!-- <a> Read More</a> -->
                    </div>
                </div>
                <div class="hm_services_main row">
                    <div class="hm_service_block col-md-4">
                        <img src="assets/Services-icons/Urban plan.png">
                        <h2>Urban plan</h2>
                        <p>We always build cities that feel like home. We make use of maximum  space and an economic designing. We oversee the future and build it in the present With the assurance of 13 to 15 years of forecasting.</p>

                        <!-- <a> Read More</a> -->
                    </div>

                    <div class="hm_service_block col-md-4">
                        <img src="assets/Services-icons/Turnkey projects.png">
                        <h2>Turn-key projects</h2>
                        <p>We manage complex project structures and their budgets efficiently and deliver in timely manner to give the clients value to their money which are transparent and hassle free.</p>
                        <!-- <a> Read More</a> -->
                    </div>

                    <div class="hm_service_block col-md-4">
                        <img src="assets/Services-icons/Project management.png">
                        <h2>Project management</h2>
                        <p>We pay attention to the completion of your project within the time frame. We always finish the project within the given budget and we ensure the superior quality.</p>
                        <!-- <a> Read More</a> -->
                    </div>

                </div>
            </div>
        </div>
    </section>

<!-- awards -->
<section class="hm_event_sec">
    <div class="container hm_event_main">
        <div class="row">
            <div class="hm_heading_1">AWARDS</div>
            <h2>
                What's happening <br> at Prakriti
            </h2>
        </div>

        <div class="row">

            <div class="col-md-12 p-0">
                <a routerLink="/awards-details-one/">
                    <div class="hm_event_tabs">
                        <div class="hm_tab_container row">
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/awards/National-Awards-1.jpg" style="height: fit-content;padding: 0;">
                            </div>

                            <div class="info_cnt col-md-6">
                                <h5 class="text-center line-height-32">
                                    <strong>
                                        Winner of National Architectural and Interior Design Excellence
                                        Awards 2022
                                    </strong>
                                </h5>
                                <!-- <p>Colour is something that helps us recall memories and feelings.</p> -->
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-12 mt-5 p-0">
                <a routerLink="/awards-details-two/">
                    <div class="hm_event_tabs">
                        <div class="hm_tab_container row">
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/awards/All-Market-Orion-Mall.jpg" style="height: fit-content;padding: 0;">
                            </div>

                            <div class="info_cnt col-md-6">
                                <h5 class="text-center line-height-32 mb-3">
                                    <strong>
                                        Opening ceremony of All Market<br>
                                        (Orion Avenue Mall)
                                    </strong>
                                </h5>
                                <p>Bengaluru with Chief Guest Mr. M.R Jaishankar, Chairman and
                                    Managing Director of Brigade Group.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <!-- <div class="col-md-12 mt-5 p-0">
                <div class="hm_event_tabs">
                    <div class="hm_tab_container row">
                        <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                            <img class="img-fluid" src="assets/awards/dummy.jpeg" style="height: fit-content;padding: 0;">
                        </div>

                        <div class="info_cnt col-md-6">
                            <h5 class="text-center line-height-32 mb-3">
                                <strong>
                                    Opening ceremony of All Market <br/>
                                    (Orion Uptown Mall)
                                </strong>
                            </h5>
                            <p>Bengaluru with Chief Guest Dr. P.C Jaffer IAS, Finance secretary,<br/> Karnataka</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-12 mt-5 p-0">
                <a routerLink="/awards-details-three/">
                    <div class="hm_event_tabs">
                        <div class="hm_tab_container row">
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/awards/NaikattyJumaMasjid.jpg" style="height: fit-content;padding: 0;">
                            </div>

                            <div class="info_cnt col-md-6">
                                <h5 class="text-center line-height-32 mb-3">
                                    <strong>
                                        Honored by Indusrial Minister<br> Shri.P.K Kunhalikutty
                                    </strong>
                                    for the outstanding work of <strong>Naikatty Juma Masjid.</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-12 mt-5 p-0">
                <a routerLink="/awards-details-four">
                    <div class="hm_event_tabs">
                        <div class="hm_tab_container row">
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/awards/Snehatheeram.jpg" style="height: fit-content;padding: 0;">
                            </div>

                            <div class="info_cnt col-md-6">
                                <h5 class="text-center line-height-32 mb-3">
                                    Inauguration function of <br>
                                    <strong> Snehatheeram convention center, Kerala, With Chief Guest Shri. Panakkad Sadiq Ali Shihab Thangal.
                                    </strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-12 mt-5 p-0">
                <a routerLink="/awards-details-five">
                    <div class="hm_event_tabs">
                        <div class="hm_tab_container row">
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/awards/Hyatt.JPG" style="height: fit-content;padding: 0;">
                            </div>

                            <div class="info_cnt col-md-6">
                                <h5 class="text-center line-height-32 mb-3">
                                    House warming ceremony <br>
                                    of the residence <strong>'Hyatt'</strong> of <strong>Nehla Group</strong>, 
                                    with chief guest Honorable <strong>Ahmed Algharib</strong>.

                                    
                                </h5>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

        </div>


    </div>
</section>
<!-- /awards -->


    <section class="journals_sec" style="background-image: url(assets/pattern_left.png);">
        <div class="container hm_journal_main">
            <div class="row">
                <div class="hm_heading_1">JOURNALS</div>
                <h2>
                    What's happening <br> at Prakriti
                </h2>

                <div class="journal_container row">
                    <div class="col-md-4 col journal_block hide_mob" *ngFor="let blog of blogs">
                        <img [src]="blog.image_url">
                        <h2> <a [routerLink]="['/blog-details/',blog.id]">  {{blog.title}}</a></h2>
                        <!-- <h2> <a href=""></a>  {{blog.title}} </h2> -->
                        <p>{{(blog.description | htmlToPlaintext) |slice:0:100}}...</p>
                        <a [routerLink]="['/blog-details/',blog.id]"> Read More</a>
                    </div>
                </div>





                <div class="journel_hm_mob">
                    <owl-carousel-o [options]="event_carousel">

                        <ng-template carouselSlide *ngFor="let blog of blogs">



                            <img [src]="blog.image_url">
                            <h3> <a [routerLink]="['/blog-details/',blog.id]"> {{blog.title}} </a></h3>
                            <!-- <p>{{blog.description | slice : 0 :50}}...</p> -->
                            <a [routerLink]="['/blog-details/',blog.id]"> Read More</a>


                        </ng-template>
                    </owl-carousel-o>
                </div>





            </div>
        </div>
    </section>



    <section class="hm_event_sec">
        <div class="container hm_event_main">
            <div class="row">
                <div class="hm_heading_1">EVENTS</div>
                <h2>
                    What's happening <br> at Prakriti
                </h2>
            </div>

            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="hm_event_tabs">

                        <div class="crs_tab">
                            <button class="btn1" *ngFor="let cat of event_category" [ngClass]="{'active' : active_cat == cat.id}" (click)="filterCategory(cat.id)">{{cat.title}}</button>
                        </div>
                        <owl-carousel-o [options]="event_carousel">

                            <ng-template carouselSlide *ngFor="let event of events">
                                <div class="hm_tab_container row" [routerLink]="['/event-details/',event.id]">

                                    <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                        <img class="img-fluid" [src]="event.image_url" style="height: fit-content;padding: 0;">
                                    </div>


                                    <div class="info_cnt col-md-6">
                                        <h1>{{event.type}}</h1>
                                        <h2>{{event.title}}</h2>
                                        <p>Colour is something that helps us recall memories and feelings.</p>
                                        <div class="tab_info_cnt">
                                            <div class="tab_info_left">
                                                <img src="assets/calender_icon.svg">
                                                <span>{{event.from_date |date:'dd MMM yyyy'}}, {{event.to_date}}</span>
                                                <!-- <p *ngIf="event?.to_date"></p> -->
                                            </div>
                                            <div class="tab_info_rte">
                                                <img src="assets/location.svg">
                                                <span>{{event.location}}</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </ng-template>
                        </owl-carousel-o>

                        <!-- <owl-carousel-o [options]="eventOption">
                                 <ng-template carouselSlide>
                                    <div class="owl-item" style="width: 100%;">

                                         <div style="width: 100%;display: flex;height: 100px;">
                                        PAST TEST
                                        </div> </div>
                                  </ng-template>

                                  <ng-template carouselSlide>
                                    <div class="owl-item">

                                         <div style="width: 100%;display: flex;height: 100px;">
                                        PAST 2
                                        </div> </div>
                                  </ng-template>
                                </owl-carousel-o> -->


                        <!-- <div class="hm_tab_container row" *ngIf="active_tab == 'upcoming'">

                            <div class="info_cnt col-md-6">
                                <h1>Exhibition</h1>
                                <h2>Color Memmories</h2>
                                <p>Colour is something that helps us recall memories and feelings. The steel blue of the ocean on a windy Autumn's day that gives us chills just thinking of it;
                                </p>

                                <div class="tab_info_cnt">
                                    <div class="tab_info_left">
                                        <img src="assets/calender_icon.svg">
                                        <span>25 December 2021</span>
                                        <p>10 AM - 6 PM</p>
                                    </div>
                                    <div class="tab_info_rte">
                                        <img src="assets/rupee_icon.svg">
                                        <span>25 December 2021</span>
                                        <p>10 AM - 6 PM</p>
                                    </div>
                                </div>

                            </div>
                            <div class="hm_tab_rte_cnt  col-md-6" style="display: grid; justify-content: center; vertical-align: middle; align-items: center;padding: 0;">
                                <img class="img-fluid" src="assets/Hm_evnt_img2.png" style="height: fit-content;padding: 0;">
                            </div>


                        </div> -->





                    </div>
                </div>
            </div>


        </div>
    </section>


    <!-- <section class="journals_sec awards_sec" style="background-image: url(assets/pattern_left.png); display: block;">
        <div class="container hm_awards_main">
            <div class="row">
                <div class="hm_heading_1">AWARDS</div>
                <h2>
                    What's happening <br> at Prakriti
                </h2>

                <div class="awards_container row">
                    <div class="col-md-6 awards_block" >
                        <img src="assets/awards/awards-3.jpg">
                        <h2 class="pb-1"> Wonders on Graphite</h2>
                        <h2> <a href=""></a>  </h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum accusamus nam inventore iure, molestiae quibusdam eveniet ad quod similique illum!</p>
                        <a href=""> Read More</a>
                    </div>
                    <div class="col-md-6 awards_block" >
                        <img src="assets/awards/awards-2.jpg">
                        <h2> Knowledge and Problem Solving on Structural Design</h2>
                        <h2> <a href=""></a></h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque, qui at a reprehenderit quod aperiam similique libero magni nulla repellendus repellat quae, quidem velit distinctio.</p>
                    </div>
                </div>

            </div>
        </div>
    </section> -->


</main>

<app-footer></app-footer>
