<main>

    <section class="inner_header" [ngStyle]="{'background-image': 'url(' + (( project_details && project_details.images && project_details.images.length > 0)? project_details.images[0].image_url:project_details.image_url) + ')'}">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1>{{project_details.title}}</h1> -->
        </div>
    </section>

    <section class="prd_content_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a routerLink="/home">Home</a>
                        <a routerLink="/projects">Projects</a>
                        <a class="active_brd">{{project_details.title}}</a>
                    </div>
                </div>
            </div>

        </div>

        <div class="container prj_detl_content">
            <div class="row">
                <div class="col-md-8 prj_left">
                    <h1>{{project_details.title}}</h1>
                    <p>{{project_details.description}}</p>
                    <div  *ngIf="project_details.video_link">
                       
                        <a href="{{project_details.video_link}}" target="_blank" class="btn btn-dark"><img src="assets/youtubew.png" height="30"> &nbsp;Project Video</a>

                    </div>
                   
                </div>
                <div class="col-md-4 project_info" *ngIf="project_details.client_name || project_details.location || project_details.sqft">
                    <h1>PROJECT INFO</h1>
                    <div class="prj_info_list" *ngIf="project_details.client_name">
                        <h2>Client Name :</h2>
                        <span>{{project_details.client_name}}</span>
                    </div>

                    <div class="prj_info_list" *ngIf="project_details.location">
                        <h2>Location :</h2>
                        <span>{{project_details.location}}</span>
                    </div>

                    <div class="prj_info_list" *ngIf="project_details.sqft">
                        <h2>Sqft :</h2>
                        <span>{{project_details.sqft}}</span>
                    </div>

                    <!-- <div class="prj_info_list">
                        <h2>Client :</h2>
                        <span>{{project_details.client_name}}</span>
                    </div> -->
                </div>
            </div>
        </div>

    </section>

    <section class="prj_slide_sec" *ngIf="project_details && project_details.images && project_details.images.length > 0">
        <div class="container prj_det_cnt">
            <div class="row">

                <owl-carousel-o [options]="prjdetails">

                    <ng-template carouselSlide *ngFor="let img of project_details.images;let i=index">
                        <div class="owl-item" (click)="openImage(i)">
                            <img src="{{img.thumb}}">
                        </div>
                    </ng-template>
                    <ng-template carouselSlide *ngFor="let img of project_details.images;let i=index">
                        <div class="owl-item " (click)="openImage(i)">
                            <img src="{{img.thumb}} ">
                        </div>
                    </ng-template>

                </owl-carousel-o>


            </div>
        </div>
    </section>


</main>

<app-footer [style]=" '{margin-top:-10px !important;}' "></app-footer>