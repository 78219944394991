import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ChallengeComponent } from './pages/challenge/challenge.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AchieversComponent } from './pages/achievers/achievers.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { EventNewComponent } from './pages/event-new/event-new.component';
import { EventListComponent } from './pages/event-list/event-list.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { BlogDetailsNewComponent } from './pages/blog-details-new/blog-details-new.component';
import { AwardsDetailsComponent } from './pages/awards-details/awards-details.component';
import { AwardDetailsOneComponent } from './pages/award-details-one/award-details-one.component';
import { AwardDetailsTwoComponent } from './pages/award-details-two/award-details-two.component';
import { AwardDetailsThreeComponent } from './pages/award-details-three/award-details-three.component';
import { AwardDetailsFourComponent } from './pages/award-details-four/award-details-four.component';
import { AwardDetailsFiveComponent } from './pages/award-details-five/award-details-five.component';
import { AluminiComponent } from './pages/alumini/alumini.component';
import { CareerComponent } from './pages/career/career.component';



const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'challenge', component: ChallengeComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'achievers', component: AchieversComponent },
  { path: 'alumini', component: AluminiComponent },
  { path: 'career', component: CareerComponent },
{path:'project-details/:id', component:ProjectDetailsComponent},
{path:'blog', component:EventNewComponent},
{path:'event-list', component:EventListComponent},
{path:'event-details/:id', component:EventDetailsComponent},
{path:'blog-details/:id', component:BlogDetailsNewComponent}, 
{path:'awards-details-one', component:AwardDetailsOneComponent}, 
{path:'awards-details-two', component:AwardDetailsTwoComponent}, 
{path:'awards-details-three', component:AwardDetailsThreeComponent}, 
{path:'awards-details-four', component:AwardDetailsFourComponent} ,
{path:'awards-details-five', component:AwardDetailsFiveComponent} 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    relativeLinkResolution: 'legacy',scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
