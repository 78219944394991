import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProjectService } from 'src/app/provider/project.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  project_id:any;
  project_details:any=[]

  prjdetails: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    margin: 20,
    items:3,
    autoplayHoverPause:true,
    autoplay:false,
    navSpeed: 700,
    nav: true,
    navText: ["<div class='prev_slide_bn'></div>","<div class='next_slide_bn'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 3
      }
    },
    
  }

  constructor(public route:ActivatedRoute,public project:ProjectService,public _lightbox:Lightbox) { 
    this.route.params.subscribe(val=>{
      this.project_id=val['id']
      console.log(this.project_id)
    })
  }

  ngOnInit(): void {
    this.getProjectView()
  }


  getProjectView(){
    this.project.getProjectView(this.project_id).subscribe(resp=>{
      if(resp.success){
       this.project_details=resp.data

       this.project_details.images.forEach(element => {
           
        element.thumb=element.image_url
        element.src=element.image_url
      });
      }
    })
  }


  openImage(i){
    console.log(i)
    this._lightbox.open(this.project_details.images, i);
    console.log(i)
  }
}
