<header>
    <div class="container">
        <nav class="header_nav">
            <div class="header_logo cursor">
                <img src="assets/logo.svg" routerLink="/home">
            </div>
            <div class="nav_items">

                <a [routerLink]="['/home']" fragment="academics" >
                Academics
              </a>
                <a>
                training
              </a>
                <a>
                    CONSULTING
              </a>
                <a routerLink="/incubation">
                incubation
              </a>
            </div>
            <div class="nav_toggle cursor">
                <span class="nav_btn">
                    <span></span><span></span><span></span>
                </span>
            </div>
            <div class="mega_menu" style="width: 0px;">
                <div class="inner_menu">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a [routerLink]="['/home']" fragment="academics" >Programs</a></li>
                        <li class="hide_web">
                            <a [routerLink]="['/home']" fragment="academics" >
                                Academics
                              </a>
                        </li>
                        <li><a routerLink="/blog-detail/1">Blogs</a></li>
                        <!-- <li class="hide_web">
                            <a [routerLink]="['/home']" fragment="academics">
                            Training
                          </a>
                        </li>
                        <li class="hide_web">
                            <a [routerLink]="['/home']" fragment="academics">
                            Consulting
                          </a>
                        </li> -->
                        <li class="hide_web">
                            <a routerLink="/incubation">
                            Incubation
                          </a>
                        </li>
                        <li>
                            <a routerLink="/event">
                          Events
                        </a>
                        </li>
                        <li>
                            <a routerLink="/course">
                        Course
                      </a>
                        </li>
                        <li>
                            <a routerLink="/blog">
                      Blog
                    </a>
                        </li>
                        <li>
                            <a routerLink="/news">
                    News
                  </a>
                        </li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>