import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/provider/home.service';

@Component({
  selector: 'app-alumini',
  templateUrl: './alumini.component.html',
  styleUrls: ['./alumini.component.scss']
})
export class AluminiComponent implements OnInit {
  
  aluminies:any=[]

  constructor(public home:HomeService) { }

  ngOnInit(): void {
    this.getAluminis()
    $(".aboutaimer").click(function () {
      $(".tab_about a").removeClass("active");
      $(this).addClass("active");
      $('html,body').animate({
        scrollTop: $(".abtaimerdiv").offset().top-150
      }, 'fast');
    });
    $(".vandm").click(function () {
      $(".tab_about a").removeClass("active");
      $(this).addClass("active");
      $('html,body').animate({
        scrollTop: $(".divmimi").offset().top-150
      }, 'fast');
    });
    $(".fac").click(function () {
      $(".tab_about a").removeClass("active");
      $(this).addClass("active");
      $('html,body').animate({
        scrollTop: $(".accad").offset().top-150
      }, 'fast');
    });
    $(".spe").click(function () {
      $(".tab_about a").removeClass("active");
      $(this).addClass("active");
      $('html,body').animate({
        scrollTop: $(".campdiv").offset().top-150
      }, 'fast');
    });
    $(".loca").click(function () {
      $(".tab_about a").removeClass("active");
      $(this).addClass("active");
      $('html,body').animate({
        scrollTop: $(".camp_bg").offset().top-150
      }, 'fast');
    });

    $(".tab_about a").click(function(e){
      // e.srcElement.parentElement.scrollLeft = total_nano_width - width / 2;
      var tab_about = 134*$(this).data("id");
      e.target.parentElement.scrollLeft = (tab_about/2)-40;
      console.log(e);
    });

    $(window).scroll(function(){
      if($(this).scrollTop() > 300){
        $('.tab_about').addClass('sticky')
      }
      else($('.tab_about').removeClass("sticky"));
    });
    
  }



  getAluminis(){
    this.home.getAluminis().subscribe(resp=>{
      if(resp.success){
        
        this.aluminies=resp.data.filter(x=>x.type == 'Alumini')
      }
    })
  }
}
