import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { ContactService } from 'src/app/provider/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm:any=FormGroup
  submitted:boolean=false

  constructor(public formBuilder:FormBuilder,public contact:ContactService,public common:CommonService) { }

  ngOnInit(): void {
    this.contactForm=this.formBuilder.group({
      name:['',Validators.required],
      email:['',],
      phone:['',Validators.required],
      message:['',Validators.required]
    })
  }


  get acf() { return this.contactForm.controls; }


  submitForm(){
    console.log("dddddddddddddddd")
     this.submitted=true
     if(this.contactForm.valid){
       var data={
         name:this.acf.name.value,
         email:this.acf.email.value,
         phone:this.acf.phone.value,
         message:this.acf.message.value
       }
      this.contact.sendContactData(data).subscribe(resp=>{
        if(resp.success){
          this.common.showSuccess("Successfully Submitted Your Query")
          this.contactForm.reset()
          this.submitted=false
        }
      })
     }
  }
}
