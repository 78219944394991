import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-achievers',
  templateUrl: './achievers.component.html',
  styleUrls: ['./achievers.component.scss']
})
export class AchieversComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
