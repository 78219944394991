<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header" >
        <app-header></app-header>
        <div class="container inner_banner_container">
            <h1> Journals </h1>
        </div>
    </section>

    <section class="event_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a  routerLink="/home">Home</a>
                        <a class="active_brd">Journals </a></div>
                </div>
            </div>

        </div>
        <!-- <div class="container event_select_box">
            <div class="row">
                <div class="select_inner">
                    <div class="select_box_left">


                        <div class="year_cnt_lft">
                            <span>Year</span>
                            <div class="select">
                                <select>
          <option value="1">Select Year</option>
          <option value="2">2020</option>
          <option value="3">2021</option>
        </select>
                            </div>
                        </div>

                        <div class="year_cnt_rte">
                            <span>Month</span>
                            <div class="select">
                                <select>
<option value="1">Select Month</option>
<option value="2">Jan</option>
<option value="3">Feb</option>
</select>
                            </div>
                        </div>


                    </div>


                    <div class="select_tag" *ngIf="blog_category && blog_category.length>0">
                        <span>Tags</span>
                        <ul>
                            <li *ngFor="let cat of blog_category" (click)="filterCategory(cat.id)">{{cat.title}}</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div> -->
    </section>

    <section class="event_list_sec" *ngIf="blogs && blogs.length > 0">
        <div class="container">
            <div class="row">

                <div class="col-md-4 col col-12  col-sm-6 event_tumb" *ngFor="let blog of blogs" [routerLink]="['/blog-details/',blog.id]">
                   <div class="event_tumb_img">
                    <img src="{{blog.image_url}}">
                   </div>

                    <h2>{{blog.title}}</h2>
                    <p>{{blog.description |slice:0:100}}...</p>
                    <a [routerLink]="['/blog-details/',blog.id]"> Read More</a>
                </div>



            </div>
        </div>
    </section>

</main>

<app-footer></app-footer>
