 import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public common:CommonService) { }

  ngOnInit(): void {
  }


  publishEvent(){
   this.common.publishEvent()
  }
}
