import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/provider/blog.service';

@Component({
  selector: 'app-event-new',
  templateUrl: './event-new.component.html',
  styleUrls: ['./event-new.component.scss']
})
export class EventNewComponent implements OnInit {

  is_skelton:boolean=false
  page:any=1
  per_page:any=12
  blog_cat:any='all'
  total_page:any;
  has_load:boolean=false
  blogs:any=[]
  blog_category:any=[]


  constructor(public blog:BlogService) { }

  ngOnInit(): void {
    this.getBlogCategory()
    this.getBlogs()
  }


  getBlogs(){
    this.is_skelton=true
    this.blog.getBlogs(this.page, this.per_page,this.blog_cat).subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        console.log(resp)
        this.total_page = resp.data.total / resp.data.per_page
        console.log(this.total_page)
        this.has_load = this.page < this.total_page
        console.log(this.page)
        this.blogs = this.blogs.concat(resp.data.data)
        if (this.has_load) {
          console.log("work")
          this.page++;
        } else {
        }
      }
    })
  }


  getBlogCategory(){
    this.is_skelton=true
    this.blog.getBlogCategory(1, 1000).subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        this.blog_category = resp.data.data
      }
    })
  }

  filterCategory(id){
  this.blog_cat=id
  this.page=1
  this.blogs=[]
  this.getBlogs()
  }


  onScroll() {
    this.page = this.page++
  }

}
