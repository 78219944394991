import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import {EventPublishService} from '../../provider/event-publish'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  nav_toggle:boolean =false;

  constructor(public event:EventPublishService,public common:CommonService) { }

  ngOnInit(): void {
    $(".nav_toggle").click(function(){
      $(".mega_menu").toggleClass("active");
      $(this).toggleClass("showToogle");
    });
    $(".inner_menu").click(function(){
      $(".mega_menu").toggleClass("active");
      $(".nav_toggle").toggleClass("showToogle");
    });
  }

  publishEvent(){
   this.common.publishEvent()
  }
}
