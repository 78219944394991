import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/provider/event.service';
import { Lightbox } from 'ngx-lightbox';
import { EventRegistartionComponent } from '../event-registartion/event-registartion.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExternalLibraryService } from 'src/app/provider/util';
import { CommonService } from 'src/app/provider/common.service';
import swal from 'sweetalert';
declare let Razorpay: any;
declare let RazorpayCheckout: any;

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  
  currentDate: Date = new Date();
  specificDate: Date = new Date();
  isDateBeforeSpecific(): boolean {
    return this.currentDate < this.specificDate;
  }
  is_event_big_tumb:boolean=false
  event_data:any=[]
  event_id:any;
  registerForm:any=FormGroup
  is_register:boolean=false
  
  submitted:boolean=false
  razorpayResponse:any;
  isProcessing: boolean=false
  paymentDetails:any;


  constructor(public event:EventService,public route:ActivatedRoute,public _lightbox:Lightbox,public dialog:MatDialog,public formBuilder:FormBuilder,
    public myElement: ElementRef,public razorpayService: ExternalLibraryService,public cd:ChangeDetectorRef,public common:CommonService) { 
    this.route.params.subscribe(val=>{
      this.event_id=val['id']
    })
  }

  ngOnInit(): void {
    this.getEventView()
    this.registerForm=this.formBuilder.group({
      name:['',Validators.required],
      email:['',],
      phone:['',Validators.required],
      event_id:['']
    })

    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();

  }

  
  RAZORPAY_OPTIONS = {
    "key": "",
    "amount": '',
    "name": "",
    "order_id": "",
    "description": "",
    "image": "",
    "prefill": {
      "name": '',
      "email": '',
      "contact": '',
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": ""
    }
  };

  getEventView(){
    this.is_event_big_tumb=true
        this.event.getEventView(this.event_id).subscribe(resp=>{
          if(resp.success){
            this.is_event_big_tumb=false
           this.event_data=resp.data
           console.log(this.event_data)
           this.specificDate=this.event_data.to_date;
           if(this. event_data.evt_images && this. event_data.evt_images.length>0){
           this. event_data.evt_images.forEach(element => {
           
             element.thumb=element.image_url
             element.src=element.image_url
           });
          //  this.event_data.forEach(element => {
          //   element.thumb=element.image_url
          //   element.src=element.image_url
          // });
    
         }
          console.log(this.event_data)
          }
        })
      }
    
      openImage(i){
        this._lightbox.open(this.event_data.evt_images, i);
        console.log(i)
      }
    
      // startBooking(data){
      //   data.event_name=this.event_data.title
      //   const daiogueRef=this.dailog.open(CourseFormComponent,{
      //     height: '100%',
      //     panelClass: 'form-modal-container',
      //     data: {
      //       flag:'plan',
      //       plan:data
      //     },
      //    });
      //    daiogueRef.updatePosition({ bottom: '0px', right: '0' });
      //    daiogueRef.afterClosed().subscribe(result => {
    
      //    });
      // }

      openRegisterForm(){
        this.is_register=true
        setTimeout(() => {
          document.getElementById("target").scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }


      get acf() { return this.registerForm.controls; }

      submitRegistration(){
       this.submitted=true
       if(this.registerForm.valid){
        var data={
          name:this.acf.name.value,
          email:this.acf.email.value,
          mobile:this.acf.phone.value,
          event_id:this.event_id
        }
        this.event.registerEvent(data).subscribe(resp=>{
          if(resp.success){
            console.log(resp)
            this.is_register=false
            this.submitted=false
            this.registerForm.reset()
            this.paymentDetails = resp.data
            if(resp.data.razor_data){
              this.RAZORPAY_OPTIONS.amount = resp.data.razor_data.amount;
        this.RAZORPAY_OPTIONS.description = resp.data.razor_data.description;
        this.RAZORPAY_OPTIONS.key = resp.data.razor_data.key;
        this.RAZORPAY_OPTIONS.order_id = resp.data.razor_data.order_id;
        this.RAZORPAY_OPTIONS.name = resp.data.razor_data.name;
        this.RAZORPAY_OPTIONS.prefill.name = resp.data.razor_data.prefill.name;
        this.RAZORPAY_OPTIONS.prefill.email = resp.data.razor_data.prefill.email;
        this.RAZORPAY_OPTIONS.prefill.contact = resp.data.razor_data.prefill.contact;
        this.RAZORPAY_OPTIONS.theme.color = resp.data.razor_data.theme.color;
        this.RAZORPAY_OPTIONS.image = resp.data.razor_data.image;
        // binding this object to both success and dismiss handler
        this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
          let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
          razorpay.open();
            }
          }
        })
       }
      }


      public razorPaySuccessHandler(response) {
        this.razorpayResponse = `Razorpay Response`;
        let data = {
          payment_id: this.paymentDetails.id,
          register_id:this.paymentDetails.id,
          razor_payment_id: response.razorpay_payment_id,
        }
        this.event.verifyPayment(data).subscribe(res => {
          if (res.success == true) {
            swal("Successfully Registerd");

          } else {
            this.isProcessing = false
            this.common.showToastError(res.message)
          }
        }, err => {
          this.isProcessing = false
          this.common.processError(err)
        });
        this.isProcessing = false
        this.cd.detectChanges()
      }
}
