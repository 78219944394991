<main>

    <section class="inner_header" style="background-image: url(assets/about_banner.png);">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <h1> Event Details </h1>
        </div>
    </section>


    <section class="event_det_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a routerLink="/home">Home</a>
                        <a class="active_brd">Event Details </a></div>
                </div>
            </div>

            <div class="row event_det_main ">

                <!-- <h1>{{event_data.title}}</h1> -->
                <div class="event_det_cnt">

                    <div class="col-md-7 event_det_lft"> <img src="{{event_data.image_url}}"> </div>
                    <div class="col-md-5 event_det_rte">

                        <div class="info_cnt">
                            <span>{{event_data.type}}</span>
                            <h2>{{event_data.title}}</h2>
                            <p>{{event_data.sub_title}}
                            </p>

                            <div class="tab_info_cnt">
                                <div class="tab_info_left">
                                    <img src="assets/calender_icon.svg">
                                    <span>{{event_data.from_date |date:'dd MMM yyyy'}}, {{ event_data.to_date ? event_data.to_date : ''}}</span>
                                    <!-- <span *ngIf="event_data?.to_date"></span> -->
                                </div>
                                <div class="tab_info_rte">
                                    <img src="assets/location.svg">
                                    <span>{{event_data.location}}</span>
                                </div>
                                <!-- <div class="tab_info_rte">
                                    <img src="assets/rupee_icon.svg">
                                    <span> <strong> ₹ 10000</strong> </span>
                                    <p>Include all payment</p>
                                </div> -->
                            </div>

                            <button  *ngIf="isDateBeforeSpecific()" (click)="openRegisterForm()">REGISTER NOW </button>

                        </div>
                    </div>

                </div>

                <p>{{event_data.description}}</p>

            </div>
        </div>

    </section>

    <section class="contact_inner" *ngIf="is_register" id="target">
        <div class="container">
            <div class="row">
                <h1>Register</h1>
                <div class="col-md-12 row contact_add_cnt">

                    <div class="col-md-8 contact_form my-3">
                        <form class="form-horizontal" autocomplete="off" [formGroup]="registerForm">
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="name" placeholder="Full Name">
                                    <div *ngIf="submitted && acf.name.errors" class="invalid">
                                        <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="email" placeholder="Email">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="phone" placeholder="Phone">
                                    <div *ngIf="submitted && acf.phone.errors" class="invalid">
                                        <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Phone is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-12 tr">
                                    <button class="contact_btn" type="submit" (click)="submitRegistration()">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="event_images" *ngIf="event_data && event_data.evt_images && event_data.evt_images.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-md-4 eve_img" *ngFor="let img of event_data.evt_images; let i=index" (click)="openImage(i)">
                    <img src="{{img.image_url}}">
                    <a href="#">{{img.title}}</a>
                </div>
            </div>
        </div>

    </section>


</main>
<app-footer></app-footer>