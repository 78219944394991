import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';
import { ChallengeComponent } from './pages/challenge/challenge.component';
import {MatDialogModule} from '@angular/material/dialog';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LiveComponent } from './pages/live/live.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ngx-toastr';
import { DisqusModule } from "ngx-disqus";
import { InnerHeaderComponent } from './pages/inner-header/inner-header.component';
import { PipeModule } from './pipes/pipe/pipe.module';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { EventNewComponent } from './pages/event-new/event-new.component';
import { EventListComponent } from './pages/event-list/event-list.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { BlogDetailsNewComponent } from './pages/blog-details-new/blog-details-new.component';
import { EventRegistartionComponent } from './pages/event-registartion/event-registartion.component';
import { AwardsDetailsComponent } from './pages/awards-details/awards-details.component';
import { AwardDetailsOneComponent } from './pages/award-details-one/award-details-one.component';
import { AwardDetailsTwoComponent } from './pages/award-details-two/award-details-two.component';
import { AwardDetailsThreeComponent } from './pages/award-details-three/award-details-three.component';
import { AwardDetailsFourComponent } from './pages/award-details-four/award-details-four.component';
import { AchieversComponent } from './pages/achievers/achievers.component';
import { AluminiComponent } from './pages/alumini/alumini.component';
import { AwardDetailsFiveComponent } from './pages/award-details-five/award-details-five.component';
import { CareerComponent } from './pages/career/career.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    ChallengeComponent,
    LiveComponent,
    ContactComponent,
    InnerHeaderComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    EventNewComponent,
    EventListComponent,
    EventDetailsComponent,
    BlogDetailsNewComponent,
    EventRegistartionComponent,
    AwardsDetailsComponent,
    AwardDetailsOneComponent,
    AwardDetailsTwoComponent,
    AwardDetailsThreeComponent,
    AwardDetailsFourComponent,
    AchieversComponent,
    AluminiComponent,
    AwardDetailsFiveComponent,
    CareerComponent,

  ],
  entryComponents:[EventRegistartionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    LightboxModule,
    PipeModule,
    ToastrModule.forRoot(), 
    DisqusModule.forRoot('aimerbschool-com')
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
