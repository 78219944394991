import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from 'src/app/provider/event.service';
import { EventRegistartionComponent } from '../event-registartion/event-registartion.component';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  is_skelton:boolean=false
  event_category:any=[]
  fiter_text:any='all'
  page:any=1
  per_page:any=12
  total_page:any=[]
  has_load:boolean=false
  events:any=[]
  cat_id:any;

  constructor(public event:EventService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.getEvents()
    this.getEventCategory()

  }

  getEventCategory(){
    this.is_skelton=true
    this.event.getEventCategorys().subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        this.event_category = resp.data.data
        console.log(this.event_category)
      }
    })
  }

  getEvents(){
    this.is_skelton=true
    this.event.getEvents(this.page, this.per_page,this.cat_id).subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        console.log(resp)
        this.total_page = resp.data.total / resp.data.per_page
        console.log(this.total_page)
        this.has_load = this.page < this.total_page
        console.log(this.page)
        this.events = this.events.concat(resp.data.data)
        console.log(this.events)
        if (this.has_load) {
          console.log("work")
          this.page++;
        } else {
        }
      }
    })
  }

  onScroll() {
    this.page = this.page++
  }


  filterCategory(id){
    this.cat_id=id
    this.fiter_text=id
    this.page=1
    this.events=[]
    this.getEvents()
  }


  filterType(data){
    this.fiter_text=data
    this.page=1
    this.events=[]
    this.getEvents()
  }


}
