import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safePipe';
import { HtmlToPlaintext } from './htmlToPlaintext';



@NgModule({
  declarations: [SafePipe, HtmlToPlaintext],
  imports: [
    CommonModule
  ],
  exports: [SafePipe, HtmlToPlaintext]
})
export class PipeModule { }
