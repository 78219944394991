<main>
    <section class="header" style="background-image: url(assets/bgbanner.svg); height: auto;">
        <app-header></app-header>
        <div class="container">
            <div class="breadcramp">
                <h1>
                    Ace the Case
                </h1>
                <p>
                    Its dummy content for design purpose and will be replaced with original one
Lorem Ipsum is simply dummy text of the printing and
                </p>
                <div class="nav_root">
                    <span routerLink="/home">Home</span>
                    <img src="assets/bxr.svg">
                    <span>Solve the case Challenge</span>
                </div>
            </div>
        </div>

    </section>

    <section class="about_section_inner">
        <div class="container">
            <div class="row">

                <div class="col-md-11">
                    <div class="info_box">
                        <!-- <h3 class="subttle">Ace the Case</h3> -->
                        <p class="subinfo">
                            Although every business is unique, some universal lessons can be learned from studying business cases. This helps us identify areas of weakness and areas of potential improvement. <br> Business cases are usually short and detailed
                            reports that detail a particular company's operations or a particular situation.<br> There are a variety of ways to analyse the data and information in these reports. Reading and analysing a case in a structured way will help
                            you understand the problem at the heart and come up with a recommended solution. <br> Display your mastery in the art of solving case by accepting our challenges. Watch out for our business case here.
                        </p>
                    </div>
                </div>

                <!-- <div class="col-md-12 mt20">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="list_chal">
                                <img src="assets/crs.png">
                                <div class="list_chal_flex">
                                    <h4>Solve the case Challenge</h4>
                                    <div class="date_s">
                                        <span>From: 12th Sep 2021</span>
                                        <span>To: 12th Dec 2021</span>
                                    </div>
                                    <span class="post_date">Post on 11th Sep 2021</span>
                                    <div class="list_btn_d">
                                        <button class="btn2">Download PDF</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </section>


</main>

<app-footer></app-footer>