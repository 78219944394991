<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1> Events </h1> -->
        </div>
    </section>

    <section class="event_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a  routerLink="/home">Home</a>
                        <a class="active_brd">Awards </a>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12 mt-5 award-details">
                    <h3>
                        <strong>
                            Winner of National Architectural and Interior Design Excellence 
                        Awards 2022
                        </strong>
                    </h3>
                    <br>

                        <p>
                            Award winning category – “Leading and Most Innovative Architecture and Interior Design Firm of The Year 2022” Kerala under<br/> 
                            “Residential projects, Religious projects, and Commercial projects”<br/>
                            and also selected as the“Elite Top 25 Most Prominent Architect and Designer of India”. 
                        </p>
                        <p>
                            Award presenters were Ar. Mr. Ash Parekh the founder and managing 
                            partner at Opus One Design LLP and <br> Prof. Dr. Vibhuti Sachdev an 
                            independent Designer, Consultant on conservation projects , writer and 
                            lecturer
                        </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/National-Awards-1.jpg">
                </div>
                <!-- <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/National-Awards-2.jpg">
                </div>
                <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/National-Awards-3.jpg">
                </div> -->
                <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/National-Awards-4.jpg">
                </div>
                <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/National-Awards-5.jpg">
                </div>
            </div>

        </div>





    </section>


</main>


<app-footer></app-footer>