<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-3">
                <a routerLink="/">
                    <img src="assets/Prakriti-Logo.png" class="footer_logo">
                </a>
            </div>
            <div class="col-md-3 ft_col_1 col-8">

                <div class="ft_quick_links">
                    <h1>Quick Links</h1>
                </div>

                <div class="ft_ql_cnt">
                    <div class="ft_links">
                        <!-- <a routerLink="/about">About</a> -->
                        <a routerLink="/">Home</a>
                        <a routerLink="/about">About</a>
                        <a routerLink="/projects">Projects</a>
                        <a routerLink="/alumini">Alumini</a>                                                
                    </div>

                    <div class="ft_links">
                        <a routerLink="/event-list">Events</a>
                        <a routerLink="/career">Careers</a>
                        <a routerLink="/blog">Blog</a>
                        <a routerLink="/contact">Contact&nbsp;Us</a>
                    </div>

                </div>

                
            </div>

            <div class="col-md-3 ft_col_2 col-12">
                <div class="ft_adress">
                    <div class="ft_txt_main">
                        <div class="ft_add_icon"> <img src="assets/phone.png"> </div>
                        <div class="ft_txt">
                            <a href="tel:04953500519">    PHONE <br>
                                0495 3500519</a>
                           
                        
                        </div>
                    </div>
                    <!-- <div class="ft_txt_main">
                        <div class="ft_add_icon">  <img src="assets/phone.png"></div>
                        <div class="ft_txt">
                            <a href="tel:0495 4050056"> PHONE <br>
                                0495 4050056</a>
                        </div>
                    </div> -->
                    <div class="ft_txt_main">
                        <div class="ft_add_icon">   <img src="assets/mobile_white.png"></div>
                        <div class="ft_txt">
                            <a href="tel:9074001554"> MOBILE <br>
                                90 74 001 554</a>
                        </div>
                    </div>
                    <div class="ft_txt_main">
                        <div class="ft_add_icon">  <img src="assets/mail.png"></div>
                        <div class="ft_txt">
                            <a href="mailto:info@prakritiarch.com">info@prakritiarch.com</a>
                        </div>
                    </div>
                </div>
            </div>
     
            <div class="col-md-3 ft_social_cnt ft_col_3 col-12">

                <h1> FIND US ON </h1>

                <div class="ss_media">
                    <a href="https://www.facebook.com/prakritiarchitects" target="_blank">
                        <img data-url="" src="assets/fb.svg">
                    </a>
                    <a href="https://youtube.com/@PRAKRITIARCHITECTS?si=ZIO4xVmIh1muFp6T" target="_blank">
                        <img src="assets/youtubew.png">
                    </a>
                    <a href="https://www.instagram.com/prakriti_architects/?hl=en" target="_blank">
                        <img src="assets/insta.svg">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <hr class="ftr_hr">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="ftr_section">
                    <span> © 2021 Prakriti Architects  |  All Rights Reserved </span>
                   
                </div>
            </div>
        </div>
    </div>
</footer>