import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/provider/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  active_tab:any='residentail'
  project_categories:any=[]
  is_skelton:boolean=false
  page:any=1
  per_page:any=12  
  total_page:any=0
  has_load:boolean=false
  projects:any=[]

  constructor(public project:ProjectService) { }

  ngOnInit(): void {
    this.getProjectCategory()
    this.getProjectList()
  }


  getProjectCategory(){
    this.project.getProjectCategory().subscribe(resp=>{
      if(resp.success){
      this.project_categories=resp.data
      // this.active_tab=this.project_categories[0].id
      // this.getProjectList()
      }
    })
  }

  getProjectList(){
    this.page=this.page++
    this.is_skelton=true
    this.project.getProjectList(this.page, this.per_page,this.active_tab).subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        console.log(resp)
        this.total_page = resp.data.total / resp.data.per_page
        console.log(this.total_page)
        this.has_load = this.page < this.total_page
        console.log(this.page)
        this.projects = this.projects.concat(resp.data.data)
        console.log(this.projects)
        if (this.has_load) {
          console.log("work")
          this.page++;
        } else {
        }
      }
    })
  }

  onScroll() {
    this.page = this.page++
  }

  setActiveTab(id){
    this.active_tab=id
    this.projects=[]
    this.page=1
    this.getProjectList()
  }

}
