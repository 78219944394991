<main>

    <!-- <section class="inner_header" style="background-image: url(assets/prakriti_office_inner.jpg);"> -->
    <section class="inner_header">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1 style="color: black;">Our Studio</h1> -->
        </div>
    </section>


    <section class="abt_content_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div><a  routerLink="/home">Home</a> <a class="active_brd">Careers</a></div>
                </div>
            </div>

        </div>

         <!-- <div class="container abt_content">
            <div class="row">
                <h1>Prakriti Architects</h1>
                <p>Prakriti Architects was established in the year 1995 under the leading architect Mr. Abdul Naseer. Within a short span of time we have emerged as one of the leading Architectural Consultancy firm in the state, based in Calicut. The areas
                    of market focus include Residential, Commercial/Corporate, Institutional, Hospitality, etc. With our innovative business solutions the firm has a number of projects to our credit .We provide a total solution for building construction
                    for clients. We have proved our ability in meeting our client requirements in the pre-fixed time frame and well within their financial capacity.</p>
            </div>

        </div>  -->

    </section>

   
          <section class="abt_team" *ngIf="careers && careers.length > 0">
      <div class="container">
            <div class="row">
                <h1 style="font-weight: 800;">Discover your new career</h1>
                <p>Explore opportunities</p>
                <hr> <ng-container *ngFor="let emp of careers; let i=index">
                <div class="team_content_main">

                    <div class=" col-md-5 team_img"> <img src="{{emp.image_url}}"> </div>
                    <div class="col-md-7 team_txt" >
                        <h1 style="padding-top: 30px;font-weight:600;">{{emp.title}}</h1>
                        <!-- <h2>{{emp.designation}}</h2> -->
                        <p>{{emp.description}}</p>
                        <a href="mailto:info@prakritiarch.com" target="_blank" class="btn btn-primary" >Send your CV to info@prakritiarch.com </a>

                    </div>
                </div> <hr>
            </ng-container>
            </div>
        </div>
        <!-- <div class="container">
            <div class="row">
                <h1>Our Team</h1>
             <div class="team_content_main">

                    <div class=" col-md-5 team_img"> <img src="assets/Our Team/Abdul-Naseer.jpg"> </div>
                    <div class="col-md-7 team_txt">
                        <h1>Abdul Naseer</h1>
                        <h2>Principal Consultant</h2>
                        <p>Prakriti Architects was established in the year 1995 under the leading architect Mr. Abdul Naseer. Within a short span of time we have emerged as one of the leading Architectural Consultancy firm in the state, based in Calicut. The areas
                            of market focus include Residential, Commercial/Corporate, Institutional, Hospitality, etc. With our innovative business solutions the firm has a number of projects to our credit .We provide a total solution for building construction
                            for clients. We have proved our ability in meeting our client requirements in the pre-fixed time frame and well within their financial capacity.</p>
                    </div>
                </div>
            </div>
        </div>
  -->
    </section>

  
    <!-- <section class="team_tile_sec" *ngIf="aluminies && aluminies.length > 0">
        <div class="container">
            <div class="row">
                <h1>Our Alumini</h1>
                <div class="team_tile">

                    <ng-container *ngFor="let emp of aluminies; let i=index">
                        <div class="col-md-4 col-12 team_photo">
                            <div class="photo_hover">
                                <h2>{{emp.title}}</h2>
                                <p>{{emp.designation}}</p>
                            </div>


                            <img src="{{emp.image_url}}">
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </section> -->
<!--   

    <section class="alumini_sec" *ngIf="aluminies && aluminies.length > 0">
        <div class="container">
            <div class="row">

                <h1>Alumini</h1>

                <div class="alum_cnt">

                    <div class="col-md-3 alumini_name" *ngFor="let alum of aluminies">
                        <h2>{{alum.title}}</h2>
                        <p>{{alum.designation}}</p>
                    </div>

                </div>


            </div>
        </div>


    </section> -->









</main>



<app-footer></app-footer>
