import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public api:Api) { }


  getBlogs(page,per_page,cat_id:any=0){
    let endpoint='blog'
    if(page > 0){
      endpoint=endpoint+'?page='+page
    } if(per_page > 0){
      endpoint=endpoint+'&per_page='+per_page
    }
    if(cat_id !=0){
      endpoint=endpoint+'&category='+cat_id
    }
    return this
    .api
    .get(endpoint)
  }

  getBlogCategory(page,per_page){
    let endpoint='blog/category'
    if(page > 0){
      endpoint=endpoint+'?page='+page
    } if(per_page > 0){
      endpoint=endpoint+'&per_page='+per_page
    }
    return this
    .api
    .get(endpoint)
  }

  getBlogView(id){
    let endpoint='blog/'+id
    return this
    .api
    .get(endpoint)
  }
}
