<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header" >
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1> Our Projects </h1> -->
        </div>
    </section>



    <section class="project_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>  <a  routerLink="/home">Home</a>  <a class="active_brd">Projects</a></div>
                </div>
            </div>

        </div>
    </section>




    <section class="project_tile_sec">

        <div class="container">
            <div class="row">
                <div class="col-md-12 nopadding">
                    <div class="hm_event_tabs">

                        <div class="crs_tab" *ngIf="project_categories && project_categories.length > 0">
                            <!-- <button class="btn1" [ngClass]="{'active' : active_tab == 'all'}" (click)="active_tab = 'all'">ALL</button> -->
                            <button class="btn1" [ngClass]="{'active' : active_tab == cat.id}" (click)="setActiveTab(cat.id)" *ngFor="let cat of project_categories">{{cat.title}}</button>
                        </div>
                        <div class="project_tab_cont" *ngIf="projects && projects.length > 0">

                            <div class=" project_tumb_cnt" [routerLink]="['/project-details',proj.id]" *ngFor="let proj of projects">
                                <div class="prj_img_cnt">
                                <img src="{{proj.image_url}}">
                            </div>
                             
                                <h2>{{proj.title}}</h2>
                                <p> {{proj?.category?.title}} </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>


<div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollDisabled]="!has_load" [infiniteScrollThrottle]="50" (scrolled)="getProjectList()"></div>
<div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></div> 

<app-footer></app-footer>