import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/provider/blog.service';

@Component({
  selector: 'app-blog-details-new',
  templateUrl: './blog-details-new.component.html',
  styleUrls: ['./blog-details-new.component.scss']
})
export class BlogDetailsNewComponent implements OnInit {

  blog_id:any;
  blogDetail:any=[]
  is_skelton:boolean=false
  blogs:any;

  constructor(public route:ActivatedRoute,public blog:BlogService) {  
    this.route.params.subscribe(val => {
    this.blog_id = val['id']
    console.log(this.blog_id)
    // this.pageId='log-detail/'+this.blog_id
  })

}

ngOnInit(): void {
  this.getBlogView();
  this.getBlogs()
}



changeData(id){
  console.log(id)
  this.blog_id=id
  this.blogDetail=[]
  this.getBlogView()
  this.getBlogs()
  window.scroll(0,0)
}

getBlogView(){
  this.blog.getBlogView(this.blog_id).subscribe(resp=>{
    if(resp.success){
     this.blogDetail=resp.data
    }
  })
}

getBlogs(){
  this.is_skelton=true
  this.blog.getBlogs(1,12).subscribe(resp => {
    if (resp.success) {
      this.is_skelton=false
      console.log(resp)
      this.blogs = resp.data.data
     let index=this.blogs.findIndex(x=>x.id ==this.blog_id )
     this.blogs.splice(index,1)
    }
  })
}
}
