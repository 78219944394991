import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(public api:Api) { }


  getProjectCategory(){
    let endpoint='project/category'
    return this
    .api
    .get(endpoint)
  }

  getProjectList(page,per_page,cat:any){
    let endpoint='project'
    if(page > 0){
      endpoint=endpoint+'?page='+page
    }else{
      endpoint=endpoint+'?page=0'
    } if(per_page > 0){
      endpoint=endpoint+'&per_page='+per_page
    }
    if(cat > 0){
      endpoint=endpoint+'&category_id='+cat
    }

    return this
    .api
    .get(endpoint)
  }

  getFeaturedProjectList(page,per_page,cat:any){
    let endpoint='project?is_featured=1'
    if(page > 0){
      endpoint=endpoint+'&page='+page
    }else{
      endpoint=endpoint+'?page=0'
    } if(per_page > 0){
      endpoint=endpoint+'&per_page='+per_page
    }
    if(cat > 0){
      endpoint=endpoint+'&category_id='+cat
    }

    return this
    .api
    .get(endpoint)
  }


  getProjectView(id){
    let endpoint='project/'+id
    return this
    .api
    .get(endpoint)
  }
}
