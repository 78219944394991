import {Injectable} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventPublishService {

    private dataSource = new BehaviorSubject<any>(0);
    data = this.dataSource.asObservable();

    private fooSubject = new Subject<any>();
    private fcmValue = new Subject<any>();
    private pauseTime = new Subject<any>();
    private ticket = new Subject<any>();
    private tab = new Subject<any>();
    private affair = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
    publishFcmData(data: any) {
        this.fcmValue.next(data);
    }

    getFcmObservable(): Subject<any> {
        return this.fcmValue;
    }

    publishPauseTime(data: any) {
        this.pauseTime.next(data);
    }

    getPauseTimeObservable(): Subject<any> {
        return this.pauseTime;
    }
    publishTicket(data: any) {
        this.ticket.next(data);
    }

    getTicketObservable(): Subject<any> {
        return this.ticket;
    }
    publishTab(data: any) {
        this.tab.next(data);
    }

    getTabObservable(): Subject<any> {
        return this.tab;
    }

   

    updateProfile(data) {
        this.dataSource.next(data);
    }
}