<main>
   
        <section class="inner_header" >
            <app-header></app-header>
            <div class="container inner_banner_container">
                <h1> Our Achievers </h1>
            </div>
        </section>
        <section class="achievers_sec">
            <div class="container">
                <div class="row">
                    <div class="breadcramp">
                        <div>  <a  routerLink="/home">Home</a>  <a class="active_brd">Achievers</a></div>
                    </div>
                </div>
    
            </div>
        </section>

    
   <section class="">
    <div class="container">
        <div class="row rows">
            
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      <p>Architect & Engineer</p> 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                      
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                 
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                       </div>
                </div>
            </div> -->

            <!-- <div class="col-md-2" style="padding:5px">
                <div class="card">
                    <img src="assets/Our Team/Abdul-Naseer.jpg" alt="Student" >
                    <div class="std_name">
                      <span>John Doe</span> 
                   >
                    </div>
                </div>
            </div> -->


                </div>
                </div>
   </section>
</main>
<app-footer></app-footer>