<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1> Events </h1> -->
        </div>
    </section>

    <section class="event_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a  routerLink="/home">Home</a>
                        <a class="active_brd">Awards </a>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12 mt-5 award-details">
                    <h3>
                        <strong>
                            Honored by Indusrial Minister Shri.P.K Kunhalikutty for the <br outstanding work > of Naikatty Juma Masjid.
                        </strong>
                    </h3>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mt-4">
                    <img class="img-fluid" src="assets/awards/NaikattyJumaMasjid.jpg">
                </div>

            </div>

        </div>





    </section>


</main>


<app-footer></app-footer>