<section [ngClass]="{'go_block' :!is_show_video}" *ngIf="!is_show_video" style=" background-image: url(assets/bgbanner.svg);background-color: var(--bg);">
    <i class="go_logo"><img src="assets/logo.svg"/></i>
    <h2 class="go_title">WEBISTE LAUNCH</h2>
    <div>Inauguration</div>
    <h3>Sri. Rahul Gandhi</h3>
    <span>(Hon. Member, Parliament of India)</span>
    <button type="button" class="go_btn" (click)="goToShowVideo()" [ngClass]="{'show':is_live}">Go<br>Live</button>
</section>

<section class="intro_video" *ngIf="is_show_video">
    <video autoplay preload='auto' (ended)="vidEnded()" webkit-playsinline="webkit-playsinline">
    <source src="assets/intro.mp4" type="video/mp4">
  </video>
</section>