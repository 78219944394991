<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1> Events </h1> -->
        </div>
    </section>

    <section class="event_sec">
        <div class="container">
            <div class="row">
                <div class="breadcramp">
                    <div>
                        <a  routerLink="/home">Home</a>
                        <a class="active_brd">Event </a></div>
                </div>
            </div>


            <div class="row">
                <div class="event_list_tab">
                    <button class="btn1 active" *ngFor="let cat of event_category" [ngClass]="{'active' : fiter_text == cat.id}" (click)="filterCategory(cat.id)">{{cat.title}}</button>
                </div>

                <div class="evnt_lst_tab" *ngIf="events && events.length > 0">

                    <div class="row gx-10">
                        <div class="col-md-4 col-12 evnt_box_main" *ngFor="let eve of events" [routerLink]="['/event-details',eve.id]">
                            <div class="evnt_box">
                                <div class="event_box_img">
                                    <img src="{{eve.image_url}}">
                                    <div class="evnt_box_hover">
                                        <h1>{{eve.title |titlecase}}</h1>
                                        <h2>{{eve.type |titlecase}}</h2>
                                        <div class="evt_hover_details">
                                            <div class="evt_hover_lft">
                                                <img src="assets/calender_icon.svg">
                                                <h2>{{eve.from_date |date:'dd MMM yyyy'}}</h2>
                                                <p *ngIf="eve?.evt_time">{{eve.evt_time}}</p>
                                            </div>
                                            <div class="evt_hover_rte">
                                                <img src="assets/clock_icon_new.svg">
                                                <h2>{{eve.to_date}}</h2>
                                                <p *ngIf="eve?.evt_time">{{eve.evt_time}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2>{{eve.title |titlecase}}</h2>
                                <p>{{eve.from_date |date:'dd MMM yyyy'}}</p>

                            </div>
                        </div>






                    </div>



                </div>



            </div>

        </div>





    </section>


</main>

<div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></div>

<div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollDisabled]="!has_load" [infiniteScrollThrottle]="50" (scrolled)="getEvents()"></div>

<app-footer></app-footer>