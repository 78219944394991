import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  is_show_video:boolean=false
  is_go_live:boolean=false
  is_live: boolean = false;
  constructor(public router:Router) {
    // localStorage.setItem("first_time","true")
  }

  ngOnInit(): void {
  }



  vidEnded(){
    this.is_show_video=true
    this.router.navigate(['/home'])
  }

  goToShowVideo(){
    this.is_show_video=true
  }

}
