import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(public api:Api) {
    
   }

   getTeams(){
    let endpoint='team'
    return this
    .api
    .get(endpoint)
   }
   getAluminis(){
    let endpoint='alumini'
    return this
    .api
    .get(endpoint)
   }
   getCareers(){
    let endpoint='career'
    return this
    .api
    .get(endpoint)
   }
}
