<main>

    <!-- <section class="inner_header" style="background-image: url(assets/about_banner.png);"> -->
    <section class="inner_header">
        <app-header></app-header>
        <div class="container inner_banner_container">
            <!-- <h1> CONTACT US </h1> -->
        </div>
    </section>



    <section class="contact_inner">
        <div class="container">
            <div class="row">
                <h1>Contact Us</h1>
                <div class="col-md-12 row contact_add_cnt">

                    <div class="col-md-4 contact_lft my-3">
                        <div class="contact_add">
                            <div class="icon_lft"><img src="assets/call.svg"></div>
                            <div class="contact_info"> PHONE <br>
                                <a href="tel:04953500519">0495 3500519</a> 
                                <!-- , <a href="sms:04954050056">0495 4050056</a>
                                <a href="tel:04952991553">0495 2991553</a> 
                                , <a href="sms:04954050056">0495 4050056</a> -->
                            </div>
                        </div>



                        <div class="contact_add">
                            <div class="icon_lft"><img src="assets/mobile.svg"></div>
                            <div class="contact_info"> MOBILE <br>
                                <!-- <a href="tel:+918086555111">8086555111</a> -->
                                <a href="tel:9074001554">90 74 001 554</a>
                            </div>
                        </div>



                        <div class="contact_add">
                            <div class="icon_lft"><img src="assets/mail.svg"></div>
                            <div class="contact_info"> EMAIL <br>
                                <a href="mailto:info@prakritiarch.com">info@prakritiarch.com</a>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-8 contact_form my-3">
                        <form class="form-horizontal" autocomplete="off" [formGroup]="contactForm">
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="name" placeholder="Full Name">
                                    <div *ngIf="submitted && acf.name.errors" class="invalid">
                                        <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="email" placeholder="Email">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" class="form-control" formControlName="phone" placeholder="Phone">
                                    <div *ngIf="submitted && acf.phone.errors" class="invalid">
                                        <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Phone is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <textarea class="form-control" placeholder="Message" formControlName="message" rows="4"></textarea>
                                    <div *ngIf="submitted && acf.message.errors" class="invalid">
                                        <div class="form-error-msg" *ngIf="acf.message.errors.required">*Message is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12 tr">
                                    <button class="contact_btn" type="submit" (click)="submitForm()">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="map">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="map_inner">
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3913.0920753806386!2d75.7920268!3d11.2546368!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba659474e812bad%3A0x6f88fab55187ecdb!2sMini%20Bypass%20Rd%2C%20Kozhikode%2C%20Kerala!5e0!3m2!1sen!2sin!4v1646905610678!5m2!1sen!2sin"
                            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.0276371573163!2d75.81840287452303!3d11.259377150101015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65930d54fffff%3A0x201985579fbf9e96!2sPrakriti%20Architects!5e0!3m2!1sen!2sin!4v1711782707285!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

<app-footer [style]="'{margin-top:-10px !important;}'"></app-footer>