<header>
    <div class="container">
        <nav class="header_nav">
            <style>
                
                </style>

            <!-- ====================== -->

            <div id="icon-dropdown" class="dropdown">
                <button class="dropbtn">
                    <div class="nav_social cursor">
                        <a href="#">
                            <img class="nav_social-icon" src="assets/meta.png" alt="">
                        </a>
                    </div>
                </button>
                <div class="dropdown-content">
                    <div class="nav_social  cursor">
                        <a href="https://www.facebook.com/prakritiarchitects" target="_blank">
                            <img style="width: 10px; margin-top: -3px;" class="nav_social-icon" src="assets/facebook-icon.png" alt="">
                        </a>
                    </div>
                    <div class="nav_social cursor">
                        <a href="https://www.instagram.com/prakriti_architects/?hl=en" target="_blank">
                            <img style="margin-top: -3px;" class="nav_social-icon" src="assets/instagram-icon.png" alt="">
                        </a>
                    </div>
                    <div class="nav_social cursor">
                        <a href="https://youtube.com/@PRAKRITIARCHITECTS?si=ZIO4xVmIh1muFp6T" target="_blank">
                            <img style="margin-top: -3px;" class="nav_social-icon" src="assets/youtube.png" alt="">
                        </a>
                    </div>
                </div>
              </div>

            <!-- ====================== -->

            <!-- <div style="display:flex;flex-direction:column">
                <div class="nav_social  cursor">
                    <a href="#">
                        <img style="width: 10px;" class="nav_social-icon" src="assets/facebook-icon.png" alt="">
                    </a>
                </div>
                <span style="margin-bottom: 5px;"></span>
                <div class="nav_social cursor">
                    <a href="#">
                        <img class="nav_social-icon" src="assets/twitter-icon.png" alt="">
                    </a>
                </div>
                <span style="margin-bottom: 5px;"></span>
                <div class="nav_social cursor">
                    <a href="#">
                        <img class="nav_social-icon" src="assets/meta.png" alt="">
                    </a>
                </div>
            </div> -->

            <div class="header_logo cursor">
                <!-- <img src="assets/Prakriti-Logo.png" routerLink="/home" /> -->
                <!-- <img style="max-width: 800px;width: 100%; height: auto;" src="assets/Prakriti-Logo-update-white.png" routerLink="/home" /> -->
                <img src="assets/Prakriti-Logo-update.png" routerLink="/home" />
            </div>

            <div class="nav_toggle cursor">
                <span class="nav_btn"> <span></span><span></span><span></span> </span>
            </div>
            <div class="mega_menu" style="width: 0px">
                <div class="inner_menu">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <!-- <li><a routerLink="/achievers">Achievers</a></li> -->
                        <li><a routerLink="/alumini">Alumini</a></li>
                        <li><a routerLink="/event-list">Events</a></li>
                        <li><a routerLink="/career">Careers</a></li>
                        <li><a routerLink="/blog">Journals</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>