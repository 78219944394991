import { Component, HostListener, OnInit } from '@angular/core';
import {CommonService} from '../../provider/common.service'
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import {EventPublishService} from '../../provider/event-publish'
import {HomeService} from '../../provider/home.service'
import { EventService } from 'src/app/provider/event.service';
import { BlogService } from 'src/app/provider/blog.service';
import { ProjectService } from 'src/app/provider/project.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if(window.pageYOffset > 300){
    this.startCounter()
    }
}
  
  
  is_skelton:boolean=false
  events:any=[]
  blogs:any;
  banners:any=[]
  active_event:any;
  event_category:any=[]
  projects:any=[]
  active_tab:any='past'
  active_cat:any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplayHoverPause:true,
    autoplay:false,
    navSpeed: 700,
    nav: true,
    navText: ["<div class='prev_slide_prj'></div>","<div class='next_slide_prj'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },

  }


  headerBaner: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoplayHoverPause:false,
    autoplay:true,
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
    navSpeed: 700,
    nav: true,
    navText: ["<div class='prev_slide_bn'></div>","<div class='next_slide_bn'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }



  event_carousel: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplayHoverPause:true,
    autoplay:false,
    navSpeed: 700,
    nav: true,
    navText: ["<div class='prev_slide_bn'></div>","<div class='next_slide_bn'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }


  eventOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoplayHoverPause:true,
    autoplay:false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }



  
  slideblog: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    items:1,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }



  experience:number=0
  experienceStop:any
  is_counter:boolean=false
  


  projectsCount:number=950
  projectsCountStop:any




  sqr:number=50
  sqrStop:any 



  constructor(public common:CommonService,public route:ActivatedRoute, public router:Router,public event:EventPublishService,
    public home:HomeService, public eventService:EventService,public blog:BlogService,public project:ProjectService) { 
    this.event.getObservable().subscribe((data)=>{
      console.log(data)
      if(data == 'event_publish'){
        this.moveToHash()
      }
    })
  }

  ngOnInit(): void {
    // $(".blog_min").width();
    this.getBlog();
    // this.getBanners()
    this.getEventCategory()
    this.getProjectList();
  }
  getBlog(){
    this.getBlogs()
  }

  startCounter(){
    if(!this.is_counter){
    this.is_counter=true
    this.experienceStop= setInterval(()=>{
      this.experience++;
      if(this.experience==25){
        clearInterval(this.experienceStop);
      }
    },20)

    this.projectsCountStop = setInterval(()=>{
      this.projectsCount=this.projectsCount+50;
      if(this.projectsCount==2000){
        clearInterval(this.projectsCountStop);
      }
    },20)

    this.sqrStop = setInterval(()=>{
      this.sqr++;
      if(this.sqr==90){
        clearInterval(this.sqrStop);
      }
    },20)
  }else{
    return
  }
  }
  


  ngAfterViewInit(): void {
    this.moveToHash()
  }

  moveToHash(){
    console.log("wwwwwwwwwww")
    try {
      let that=this
      setTimeout(()=>{
        that.route.fragment.subscribe(fragment => {
          if(fragment){
            console.log()
          document.querySelector('#' + fragment).scrollIntoView();
          }
        });        
      },100)     
    } catch (e) {}
  }

  getEventCategory(){
    this.is_skelton=true
    this.eventService.getEventCategorys().subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        this.event_category = resp.data.data
        this.active_event=this.event_category[0].id
        this.active_cat=this.event_category[0].id
        this.getEvents();
        console.log(this.event_category)
      }
    })
  }


  getEvents(){
    this.is_skelton=true
    this.active_event=this.active_event == 1 ? 'past' :'upcoming'
    this.eventService.getEvents(1, 12,this.active_event).subscribe(resp => {
      if (resp.success) {
        this.is_skelton=false
        this.events = resp.data.data
        console.log(this.events)
        
      }
    })
  }

  getProjectList(){
    this.is_skelton=true
    this.project.getFeaturedProjectList(1, 12,this.active_tab).subscribe(resp => {
      if (resp.success) {
        console.log(resp);
        this.projects = resp.data.data
      }
    })
  }



  // getBanners(){
  //   this.home.getBanners().subscribe(resp=>{
  //     if(resp.success){
  //       this.banners=resp.data.data
  //     }
  //   })
  // }

  getBlogs(){
    this.is_skelton=true
    this.blog.getBlogs(1,3,0).subscribe(resp => {
      if (resp.success) {
        console.log(resp)
        this.blogs =resp.data.data
      }
    })
  }


  
  filterCategory(id){
    this.active_event=id
    this.active_cat=id
    this.events=[]
    this.getEvents()
  }
}
