<section class="contact_inner">
    <div class="container">
        <div class="row">
            <h1>Contact Us</h1>
            <div class="col-md-12 row contact_add_cnt">

                <div class="col-md-8 contact_form my-3">
                    <form class="form-horizontal" autocomplete="off" [formGroup]="registerForm">
                        <div class="form-group">
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="name" placeholder="Full Name">
                                <div *ngIf="submitted && acf.name.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.name.errors.required">*Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="phone" placeholder="Phone">
                                <div *ngIf="submitted && acf.phone.errors" class="invalid">
                                    <div class="form-error-msg" *ngIf="acf.phone.errors.required">*Phone is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-md-12 tr">
                                <button class="contact_btn" type="submit" (click)="submitRegistration()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>