import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from 'src/app/provider/event.service';
import { EventListComponent } from '../event-list/event-list.component';

@Component({
  selector: 'app-event-registartion',
  templateUrl: './event-registartion.component.html',
  styleUrls: ['./event-registartion.component.scss']
})
export class EventRegistartionComponent implements OnInit {

  registerForm:any=FormGroup
  submitted:boolean=false
  event_id:any;

  constructor(public formBuilder:FormBuilder,public dialogRef: MatDialogRef<EventRegistartionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public event:EventService) {
     this.event_id=data.event_id
     }

  ngOnInit(): void {
    this.registerForm=this.formBuilder.group({
      name:['',Validators.required],
      email:['',],
      phone:['',Validators.required],
      event_id:['']
    })
  }


  get acf() { return this.registerForm.controls; }

  submitRegistration(){
   this.submitted=true
   if(this.registerForm.valid){
    var data={
      name:this.acf.name.value,
      email:this.acf.email.value,
      phone:this.acf.phone.value,
      event_id:this.event_id
    }
    this.event.registerEvent(data).subscribe(resp=>{
      if(resp.success){
        console.log(resp)
      }
    })
   }
  }

}
