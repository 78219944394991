import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-award-details-five',
  templateUrl: './award-details-five.component.html',
  styleUrls: ['./award-details-five.component.scss']
})
export class AwardDetailsFiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
