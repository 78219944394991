import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(public api:Api) { }
  

  getEventCategorys(){
    let endpoint='event/category'
     return this
    .api
    .get(endpoint)
  }

  getEvents(page,per_page,type:any=null,cat_id:any=0){
   let endpoint='event'
   if(page > 0){
    endpoint=endpoint+'?page='+page
  } if(per_page > 0){
    endpoint=endpoint+'&per_page='+per_page
  }
  if(type !=null){
    endpoint=endpoint+'&type='+type
  }
  if(cat_id !=0){
    endpoint=endpoint+'&category='+cat_id
  }
  
   return this
   .api
   .get(endpoint)
  }


  getEventView(id){
    let endpoint='event/'+id
    return this
    .api
    .get(endpoint)
  }

  registerEvent(data){
    let endpoint='event/registerevent'
    return this
    .api
    .post(endpoint,data)
  }

  verifyPayment(data) {
    let endpoint = 'event/verify-payment';
    return this
      .api
      .post(endpoint, data)
  }

}
