import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.scss']
})
export class InnerHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(".nav_toggle").click(function(){
      $(".mega_menu").toggleClass("active");
      $(this).toggleClass("showToogle");
    });
    $(".inner_menu").click(function(){
      $(".mega_menu").toggleClass("active");
      $(".nav_toggle").toggleClass("showToogle");
    });
  }

}
